import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/burn.webp';

export const ChildBurnsSpecialistForm = () => {
  useEffect(() => {
    document.title = 'Best Child Burn Specialist in Gurgaon!'; // Set the desired title
  }, []); //
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "jg1401r",
              attributes: {
                buttonText: "Next",
                label: "<b>Best Child Burn Specialist in Gurgaon!<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>
                MBBS, MS, MCh.<br>
                Plastic & Reconstructive Surgery
                <h2>Heal Child Burns With Care!</h2>
                Gentle & Child-Friendly Treatment&nbsp;&nbsp; ✅<br>
                Healing with Compassion&nbsp;&nbsp;✅<br>
                Quick Recovery&nbsp;&nbsp;✅<br>
                Affordable Treatment&nbsp;&nbsp;✅
                `,
                attachment: {
                  type: "image",
                  url: image
                    
                },
                attachmentMaxWidth: "300px"
              }
            },
           {
              "name": "multiple-choice",
              "id": "q_where",
              "attributes": {
                "required": true,
                "label": "Where is the burn located?",
                "choices": [
                  {
                    "label": "Face",
                    "value": "face"
                  },
                  {
                    "label": "Neck/Chest",
                    "value": "neck_chest"
                  },
                  {
                    "label": "Arms/Hands",
                    "value": "arms_hands"
                  },
                  {
                    "label": "Legs/Thighs",
                    "value": "legs_thighs"
                  },
                  {
                    "label": "Back",
                    "value": "back"
                  },
                  {
                    "label": "Other",
                    "value": "other"
                  }
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_age_group",
              "attributes": {
                "required": true,
                "label": "What's the age of the child?",
                "description": "This is going to help us make the best recommendations to you",
                "choices": [
                  {
                    "label": "Below 1 year",
                    "value": "below_1"
                  },
                  {
                    "label": "1 to 3 years",
                    "value": "1_3"
                  },
                  {
                    "label": "3 to 7 years",
                    "value": "3_7"
                  },
                  {
                    "label": "7 to 15 years",
                    "value": "7_15"
                  },
                  {
                    "label": "Above 15 years",
                    "value": "above_15"
                  }
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_when_happen",
              "attributes": {
                "label": "When did the burn happen?",
                "choices": [
                  {
                    "label": "Today",
                    "value": "today"
                  },
                  {
                    "label": "Yesterday",
                    "value": "yesterday"
                  },
                  {
                    "label": "2 to 5 days ago",
                    "value": "2_5_days_ago"
                  },
                  {
                    "label": "5 days to 1 month ago",
                    "value": "5_days_to_1_month"
                  },
                  {
                    "label": "Over 1 month",
                    "value": "over_1_month"
                  }
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_when",
              "attributes": {
                "label": "How soon are you looking for a treatment?",
                "choices": [
                  {
                    "label": "As soon as possible",
                    "value": "asap"
                  },
                  {
                    "label": "Within a week",
                    "value": "within_a_week"
                  },
                  {
                    "label": "Within a month",
                    "value": "within_a_month"
                  },
                  {
                    "label": "Not sure",
                    "value": "not_sure"
                  }
                ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "What's your phone number?"
              }
            },
            
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thanks! We\'ll be in touch soon!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'child_burn'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
            setTimeout(() => {
              window.location.href = 'https://drshikhabansal.com';
            }, 3000); 
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
