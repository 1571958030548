import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/abdominoplasty.webp'; // Ensure you have an appropriate image

export const AbdominoplastyForm = () => {
  useEffect(() => {
    document.title = 'Female Tummy Tuck Specialist in Gurgaon'; // Updated title
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <Form
        formId="2" // Updated formId
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "bph1001r",
              attributes: {
                buttonText: "Next",
                label: "<b>Top Tummy Tuck Surgeon in Gurgaon<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>MBBS, MS, MCh.<br>Plastic & Reconstructive Surgery<br><h2>Transform Your Body with Tummy Tuck!</h2>Safe & Effective Tummy Tuck&nbsp;&nbsp;✅<br>Customized Treatment Plans&nbsp;&nbsp;✅<br>Minimized Downtime&nbsp;&nbsp;✅<br>Enhanced Body Contour&nbsp;&nbsp;✅`,
                attachment: {
                  type: "image",
                  url: image
                },
                attachmentMaxWidth: "300px"
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_purpose",
              "attributes": {
                "required": true,
                "label": "Why are you looking for Tummy Tuck?",
                "choices": [
                  {"label": "Cosmetic reasons", "value": "cosmetic_reasons"},
                  {"label": "Medical reasons", "value": "medical_reasons"},
                  {"label": "Weight loss", "value": "weight_loss"},
                  {"label": "Post-pregnancy concerns", "value": "post_pregnancy_concerns"},
                  {"label": "Other", "value": "other"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_age_group",
              "attributes": {
                "required": true,
                "label": "What is your age group?",
                "choices": [
                  {"label": "Below 18", "value": "below_18"},
                  {"label": "18-30", "value": "18_to_30"},
                  {"label": "31-50", "value": "31_to_50"},
                  {"label": "51-65", "value": "51_to_65"},
                  {"label": "Above 65", "value": "above_65"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_location",
              "attributes": {
                "required": true,
                "label": "Where are you located?",
                "choices": [
                  {"label": "Gurgaon", "value": "gurgaon"},
                  {"label": "Delhi", "value": "delhi"},
                  {"label": "Faridabad", "value": "faridabad"},
                  {"label": "Ghaziabad", "value": "ghaziabad"},
                  {"label": "Other", "value": "other"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_when",
              "attributes": {
                "required": true,
                "label": "How soon are you looking for Tummy Tuck treatment?",
                "choices": [
                  {"label": "As soon as possible", "value": "asap"},
                  {"label": "Within a week", "value": "within_a_week"},
                  {"label": "Within a month", "value": "within_a_month"},
                  {"label": "> 1 month", "value": "more_than_a_month"},
                  {"label": "Not sure", "value": "not_sure"}
                ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                required: true,
                description: "We value your trust and privacy. Your phone number will be securely shared with our doctor for necessary communication only!",
                label: "What's your phone number?"
              }
            },
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thank you! We will contact you shortly!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'abdominoplasty'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
            setTimeout(() => {
              window.location.href = 'https://drshikhabansal.com';
            }, 3000); 
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
