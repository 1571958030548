import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import "./App.css";
import { useEffect } from 'react';
import { convertFormat, prepareWebhookData, submitPrivrWebhook } from './utils'; // Adjust the path as needed
import image from './images/hymaneoplasty.webp'; // Ensure you have an appropriate image

export const HymenoplastyForm = () => {
  useEffect(() => {
    document.title = 'Hymenoplasty Surgeon in Gurgaon'; // Updated title
  }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <Form
        formId="2" // Updated formId
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "bph1001r",
              attributes: {
                buttonText: "Next",
                label: "<b>Premier Hymenoplasty Surgeon in Gurgaon<b><br>🌟🌟🌟🌟🌟",
                customHTML: `<br><b>Dr. Shikha Bansal</b><br>
                MBBS, MS, MCh.<br>
                Plastic & Reconstructive Surgery<br>
                <h2>Restore Your Confidence!</h2>
                Compassionate & Confidential Care&nbsp;✅<br>
                Quick & Painless Procedure &nbsp;&nbsp;✅<br>
                Minimal Downtime &nbsp;&nbsp;✅<br>
                Quick Healing&nbsp;&nbsp;✅<br>
                `,
                attachment: {
                  type: "image",
                  url: image
                },
                attachmentMaxWidth: "300px"
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_issue",
              "attributes": {
                "required": true,
                "label": "What issue are you looking to address?",
                "choices": [
                  {"label": "Restoration of membrane integrity", "value": "restoration"},
                  {"label": "Post-injury reconstruction", "value": "post_injury"},
                  {"label": "Personal or cultural reasons", "value": "personal_cultural"},
                  {"label": "Revirginization", "value": "revirginization"},
                  {"label": "Other reasons", "value": "others"}                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_age_group",
              "attributes": {
                "required": true,
                "label": "What's your age group?",
                "choices": [
                  {"label": "18-30 years", "value": "18_30"},
                  {"label": "31-45 years", "value": "31_45"},
                  {"label": "46-60 years", "value": "46_60"},
                  {"label": "Above 60 years", "value": "above_60"}
                ]
              }
            },
            {
              "name": "multiple-choice",
              "id": "q_interest_level",
              "attributes": {
                "label": "What's your level of interest in hymenoplasty?",
                "choices": [
                  {"label": "Gathering information", "value": "gathering_info"},
                  {"label": "Considering the procedure", "value": "Considering the procedure"},
                  {"label": "Highly interested", "value": "highly_interested"},
                  {"label": "Ready to schedule", "value": "ready_to_schedule"}
                  ]
              }
            },
            {
              name: "short-text",
              id: "name",
              attributes: {
                required: true,
                label: "What's your name?"
              }
            },
            {
              name: "number",
              id: "phone",
              attributes: {
                required: true,
                description: "We value your trust and privacy. Your phone number will be securely shared with our doctor for necessary communication only!",
                label: "What's your phone number?"
              }
            },
          ],
          messages: {
            'block.defaultThankYouScreen.label': 'Thank you! We will contact you shortly!'
          },
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
            showQuestionsNumbers: true
          },
          theme: {
            font: "Roboto",
            questionsColor: "#1c3d5a",
            buttonsBgColor: "#1c3d5a",
            logo: {
              src: ""
            },
            answersColor: "#1c3d5a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#1c3d5a",
            progressBarBgColor: "#ccc"
          }
        }}
        onSubmit={(data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
          let convertedData = convertFormat(data);
          convertedData['type'] = 'hymenoplasty'
          let webHookData = prepareWebhookData(convertedData);
          console.log(JSON.stringify(convertedData));
          console.log(JSON.stringify(webHookData));
          let mode = window.location.hostname === 'localhost' ? 'dev' : 'prod';
          submitPrivrWebhook(webHookData, {mode: mode, mockSuccess: true}).then((response) => {
            setIsSubmitting(false);
            completeForm();
            setTimeout(() => {
              window.location.href = 'https://drshikhabansal.com';
            }, 3000); 
          })
          .catch((error) => {
            setIsSubmitting(false);
            setSubmissionErr(error.message)
          });
        }}
      />
    </div>
  );
};
